import React, {FunctionComponent, useEffect, useState} from 'react';
import {ResultType} from "../App";

type ResultScreenType = {
    results: ResultType[];
    setContinueButtonPressed: any;
};

export const ResultScreen : FunctionComponent<ResultScreenType> = (props) => {
    const getText = (results : ResultType[]) => {
        if (results.length == 1) {
            return <h1 className={"App-h1"} style={{marginBottom: "20px"}}>
                Within <b>{results[0].weeks}</b> weeks, you will get <b>{results[0].score}</b> points on your SAT
            </h1>;
        }
        else {
            return <h1 className={"App-h1"} style={{marginBottom: "20px"}}>
                You will get <b>{results[0].score}</b> within <b>{results[0].weeks}</b> weeks <br/> and <b>{results[1].score}</b> within <b>{results[1].weeks}</b> weeks
            </h1>;
        }
    }

    const handleClick = () => {
        props.setContinueButtonPressed(true);
    }

    const [text, setText] = useState(getText(props.results));

    useEffect(() => {
        setText(getText(props.results));
    }, [props]);

    return (
        <div>
            {text}
            <h2 className={"App-h2"} style={{marginBottom: "20px"}}>The AI generated the predictions based on answers of more than<br/> <b>3 000</b> students</h2>
            <button className={"App-button"} onClick={handleClick}>Learn more</button>
        </div>
    );
}

export default ResultScreen;
