import React from 'react';
import styled from "styled-components";
import logo from '../images/logo.svg';
import {FunctionComponent} from "react";

const LogoImg = styled.img`
  position: relative;
  max-height: 20px;
  margin: 10px 0px 0px 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  flex-direction: row;

  src: ${logo}
`;


export const Logo : FunctionComponent = () => {
    return (
        <LogoImg src={logo}/>
    );
}
export default Logo;
