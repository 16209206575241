import {FunctionComponent} from 'react';

type OfferScreenProps = {

};

export const OfferScreen : FunctionComponent<OfferScreenProps> = (props) => {
    const handleOnclick = () => {
        window.location.href = 'https://calendly.com/smartschool/10-minute-call';
    };

    // Give credibility
    // Make them feel understood
    // Everything you need is here
    // CTA

    return (
        <div>
            <h1 className={"App-h1"} style={{marginBottom: "20px", marginTop: "-20vh"}}>
                We've already helped more than 1300 students get their scores <br/>
                <b>Now it's your time to succeed.</b>
            </h1>
            <div className={"App-h2"} style={{marginBottom: "20px", textAlign: "left"}}>
                Everything you need is here: <br/><br/>
                <ol style={{paddingLeft: "1.2rem"}}>
                    <li>
                        <span style={{backgroundColor: "rgb(93,226,60, 0.2)"}}>Personal tutor</span> - your certified tutor will practice with you all techniques, tricks, and strategies during 1:1 online sessions.
                    </li>
                    <li>
                        <span style={{backgroundColor: "rgb(93,226,60, 0.2)"}}>Convenient homeworks</span> - short quizzes and challenges optimised to maximise your score without hours of hard work.
                    </li>
                    <li>
                        <span style={{backgroundColor: "rgb(93,226,60, 0.2)"}}>AI made by experts</span> - our AI algorithms will make sure your practice is tailor made just for you.

                    </li>
                </ol>
                Excited? Overwhelmed?
                Don't worry,
                SAT preparation can be hard and overwhelming but we will make sure you will get your score safe and fast. <br/> <br/>
                <span style={{color: "rgb(22, 35, 48)"}}>
                    <b>
                        Let's start from a 10 minutes call with our SAT advisor to explore your needs and help you get started.
                    </b>
                </span>
            </div>
            <button className={"App-button"} onClick={handleOnclick}>Book a call</button>
            <br/>
            <br/>
            <br/>
        </div>
    );
}

export default OfferScreen;
