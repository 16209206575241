import React, {FunctionComponent} from 'react';

import ProgressBar from 'react-bootstrap/ProgressBar';


type ProcessingScreenType = {
    percentage : number;
};

export const ProcessingScreen : FunctionComponent<ProcessingScreenType> = (props) => {
    return (
        <div>
            <h1 className={"App-h1"}>AI is calculating your results</h1>
            <h2 className={"App-h2"} style={{marginBottom: "20px"}}>This may take up to 30 seconds</h2>
            <ProgressBar now={props.percentage}  variant={"#1B8AFF"} />
        </div>
    ); // variant="success"
}

export default ProcessingScreen;
