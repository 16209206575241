import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from 'react-router-dom';

import {App} from "./App";


const AppRouter = (props : any) => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="*" element={<div/>} />
            </Routes>
        </Router>
    );
}

export default AppRouter;
