import './App.css';

import React, {FunctionComponent, useEffect, useState} from 'react';
import Logo from "./components/Logo";
import {useSearchParams} from "react-router-dom";
import {Amplify, API, graphqlOperation} from "aws-amplify";
import ProcessingScreen from "./components/ProcessingScreen";
import ResultScreen from "./components/ResultScreen";

import awsExports from "./aws-exports";
import OfferScreen from "./components/OfferScreen";
Amplify.configure(awsExports);

type AppPropsType = {

};

type GQLResponseType = {
    weeks: [number];
    scores: [number];
};

export type ResultType = {
    weeks: number;
    score: number;
};

const mobileBreakPointWidth : number = 576;

const minWaitingTime : number = 8;

const getSATScorePredictionGQL = `
query getSATScorePredictionGQL($id: ID!) {
  getSatScorePredictions(id: $id) {
    weeks
    scores
  }
}
`;

export const App : FunctionComponent<AppPropsType> = (props) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [currentTime, setCurrentTime] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [results, setResults] = useState<ResultType[]>([]);
    const [isContinueButtonPressed, setIsContinueButtonPressed] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    const processGQLResponse = (response : GQLResponseType) => {
        let newResults : ResultType[] = [];

        for (let i = 0; i < response.scores.length; ++i) {
            newResults.push({
                score: response.scores[i],
                weeks: response.weeks[i]
            });
        }

        return newResults;
    };

    const fetchResults = async (userId : string) => {
        await (
            API.graphql(graphqlOperation(getSATScorePredictionGQL, {id: userId})) as Promise<any>
        ).then(async response => setResults(processGQLResponse(response.data.getSatScorePredictions))
        ).catch(async err => {
            fetchResults(userId)
        });
    }

    useEffect(() => {
        if (currentTime > minWaitingTime && !isLoaded && results.length > 0) {
            setIsLoaded(true);
        }
    }, [currentTime]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setCurrentTime(currentTime + 1);
        }, 1000);

        return () => clearTimeout(timer);
    });

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);

        window.addEventListener("resize", handleWindowResize);

        return () => window.removeEventListener("resize", handleWindowResize);

    }, []);

    useEffect(() => {
        const userId = searchParams.get('id');
        if (userId !== null && userId !== undefined) {
            fetchResults(userId);
        }
    }, [searchParams]);

    return (
        <div className="App">
          <header className="App-header">
            <Logo />
          </header>
          <div style={{
              marginTop: "35vh",
              marginLeft: width > mobileBreakPointWidth ? "20vw" : "30px",
              marginRight: width > mobileBreakPointWidth ? "20vw" : "30px"
          }}>
              {isLoaded
                  ? (!isContinueButtonPressed ? <ResultScreen results={results} setContinueButtonPressed={setIsContinueButtonPressed} /> : <OfferScreen />)
                  : <ProcessingScreen percentage={Math.min(100, 100 * currentTime / (minWaitingTime + 1))} /> }
          </div>
        </div>
    );
}

export default App;
